<div
  class="clickable-popup-dialog_trigger"
  [class]="triggerClass"
  [class.clickable-popup-dialog_trigger--menu-open]="isMenuOpen"
  [matMenuTriggerFor]="menuRef"
  [matTooltip]="triggerTooltipTranslationKey | translate"
  (menuClosed)="menuClosed()"
  (menuOpened)="menuOpened()">
  <ng-content select="[popupTrigger]"></ng-content>
</div>

<mat-menu #menuRef="matMenu" [class]="contentClass">
  <ng-content select="[popupContent]"></ng-content>
</mat-menu>

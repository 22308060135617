import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import { AuthService } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { AccountMember, FailedAddedEmail, UserGroup } from '@celum/sacc/domain';
import { UserGroupMemberAddedError, UserGroupResourceService } from '@celum/sacc/shared';
import { ErrorService, PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

import { CreateEditUserGroupDialogComponent } from './create-edit-user-group-dialog.component';

export type CreateEditUserGroupDialogState = {
  accountId: string;
  userGroup: UserGroup;
  hasBottom: boolean;
  isReadonly: boolean;
  loading: boolean;
  addedUsers: AccountMember[];
  removedUsers: AccountMember[];
  token?: string;
  tokenExpiration?: number;
  hasNameConflict?: boolean;
};

export type CreateEditUserGroupDialogViewModel = {
  isEditMode: boolean;
  headerTextKey: string;
  totalElementCount: number;
  usersToShow: AccountMember[];
  userGroup?: UserGroup;
} & Omit<CreateEditUserGroupDialogState, 'accountId' | 'tokenExpiration'>;

@Injectable()
export class CreateEditUserGroupDialogService extends SimplePagedListService<AccountMember, CreateEditUserGroupDialogState> {
  public vm$ = this.select(state => this.createViewModel(state));

  public createUserGroup = this.effect<string>(group$ =>
    group$.pipe(
      tap(() => this.patchState({ loading: true, hasNameConflict: false })),
      switchMap((userGroupName: string) =>
        this.userGroupService.createUserGroup(
          userGroupName,
          this.get().addedUsers.map(user => user.email),
          this.get().accountId
        )
      ),
      tapAllResponses(
        () => this.dialogRef.close(true),
        (error: HttpErrorResponse) => {
          if (error.status === UserGroupMemberAddedError.CREATION_FAILED) {
            const groupedErrors = CreateEditUserGroupDialogService.groupErrors(error.error as FailedAddedEmail[]);
            this.errorService.error(`CreateEditUserGroupDialog`, '', groupedErrors, false, true);
          } else if (error.status === UserGroupMemberAddedError.NAME_CONFLICT) {
            this.patchState({ loading: false, hasNameConflict: true });
          } else {
            this.errorService.error(`CreateEditUserGroupDialog`, '', error, false, true);
          }
        },
        () => this.patchState({ loading: false })
      )
    )
  );

  public editUserGroup = this.effect<{ userGroupName: string }>(group$ =>
    group$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(({ userGroupName }) => {
        return this.userGroupService.editUserGroup(
          this.get().userGroup?.id,
          userGroupName,
          this.get().addedUsers.map(user => user.email),
          this.get().removedUsers.map(user => user.email),
          this.get().accountId
        );
      }),
      tapAllResponses(
        () => this.dialogRef.close(true),
        (error: HttpErrorResponse) => {
          if (error.status === UserGroupMemberAddedError.NAME_CONFLICT) {
            this.patchState({ loading: false, hasNameConflict: true });
          } else {
            this.errorService.error(`CreateEditUserGroupDialog`, '', error, false, true);
          }
        },

        () => this.patchState({ loading: false })
      )
    )
  );
  private isRefreshingToken = false;

  constructor(
    private userGroupService: UserGroupResourceService,
    private errorService: ErrorService,
    protected dialogRef: MatDialogRef<CreateEditUserGroupDialogComponent>,
    private authService: AuthService
  ) {
    super(
      {
        serverCall: (offset: number, batchSize: number) => this.getUserGroupMembers(offset, batchSize),
        batchSize: 20
      },
      {
        accountId: undefined,
        userGroup: undefined,
        hasBottom: false,
        isReadonly: false,
        loading: false,
        addedUsers: [],
        removedUsers: []
      }
    );

    this.select(state => state)
      .pipe(
        mergeMap(state => {
          if (!this.isRefreshingToken && (!state.token || state.tokenExpiration === undefined || Date.now() > state.tokenExpiration)) {
            this.isRefreshingToken = true;
            return this.authService.getAuthResult().pipe(
              tap(result => {
                this.patchState({ tokenExpiration: result.expiresOn.getTime(), token: result.idToken });
                this.isRefreshingToken = false;
              })
            );
          }
          return of();
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public init(accountId: string, userGroup: UserGroup, isReadonly: boolean): void {
    this.patchState({ accountId, userGroup, isReadonly });
    this.load();
  }

  public getUserGroupMembers(offset: number, batchSize: number): Observable<Result<AccountMember>> {
    return this.userGroupService.getUserGroupMembers(this.get().accountId, this.get().userGroup?.id, { offset, limit: batchSize }).pipe(
      tap(result => this.patchState({ hasBottom: result.paginationInfo.hasBottom })),
      catchError(error => {
        this.errorService.error('CreateEditUserGroupDialogService', '', error, false, true);
        return of({
          data: [] as AccountMember[],
          paginationInfo: {
            totalElementCount: 0,
            hasBottom: false,
            hasTop: false
          }
        });
      })
    );
  }

  public addUser(user: AccountMember): void {
    if (this.get().removedUsers.some(removedUser => removedUser.email === user.email)) {
      this.patchState({ removedUsers: this.get().removedUsers.filter(u => u.email !== user.email) });
    } else if (!this.get().addedUsers.some(addedUser => addedUser.email === user.email)) {
      this.patchState({ addedUsers: [...this.get().addedUsers, user] });
    }
  }

  public removeUser(user: AccountMember): void {
    if (this.get().addedUsers.some(addedUser => addedUser.email === user.email)) {
      this.patchState({ addedUsers: this.get().addedUsers.filter(u => u.email !== user.email) });
    } else if (!this.get().removedUsers.some(removedUser => removedUser.email === user.email)) {
      this.patchState({ removedUsers: [...this.get().removedUsers, user] });
    }
  }

  private createViewModel(state: PagedListState<AccountMember> & CreateEditUserGroupDialogState): CreateEditUserGroupDialogViewModel {
    const isEditMode = !!state.userGroup && !state.isReadonly;

    return {
      ...state,
      totalElementCount: state.paginationInfo?.totalElementCount + state.addedUsers.length - state.removedUsers.length,
      isEditMode,
      usersToShow: [...state.addedUsers, ...state.data].filter(user => !state.removedUsers.some(u => u.email === user.email)),
      headerTextKey: state.isReadonly
        ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.READONLY'
        : isEditMode
          ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.EDIT'
          : 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.CREATE'
    };
  }

  private static groupErrors(errors: FailedAddedEmail[]): { reason: string; emails: string[] }[] {
    const groupedErrors: { reason: string; emails: string[] }[] = [];
    for (const currentError of errors) {
      if (!groupedErrors.some(error => error.reason === currentError.reason)) {
        groupedErrors.push({ reason: currentError.reason, emails: [currentError.email] });
      } else {
        groupedErrors.find(error => error.reason === currentError.reason).emails.push(currentError.email);
      }
    }

    return groupedErrors;
  }
}

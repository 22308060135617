import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroupDirective, NgForm, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AccountAccess, UserState } from '@celum/authentication';
import {
  CelumBannerModule,
  CelumButtonModule,
  CelumDialogModule,
  CelumEmptyPageModule,
  CelumMessageModule,
  ColorConstants,
  EmptyPage,
  IconConfiguration
} from '@celum/common-components';
import { CelumListModule, CelumRemainingCharactersComponent } from '@celum/internal-components';
import { CelumDirectivesModule, CelumValidators } from '@celum/ng2base';
import { AccountMember, SaccProperties, UserGroup } from '@celum/sacc/domain';
import { SearchAndSelectComponent } from '@celum/shared/ui';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { UserNamePipe } from '@celum/shared/util';

import { CreateEditUserGroupDialogService } from './create-edit-user-group-dialog.service';
import { UserFilterComponent } from './user-filter/user-filter.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { TableCountComponent } from '../table-count/table-count.component';

export interface CreateEditUserGroupDialogData {
  accountAccess?: AccountAccess;
  existingUserGroup?: UserGroup;
  isReadonly?: boolean;
  accountId: string;
}

@Component({
  selector: 'sacc-create-edit-user-group-dialog',
  templateUrl: './create-edit-user-group-dialog.component.html',
  styleUrls: ['./create-edit-user-group-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Needed to remove avatar padding
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,

    MatButtonModule,
    MatInputModule,
    MatTooltipModule,

    CelumBannerModule,
    CelumButtonModule,
    CelumDialogModule,
    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumListModule,
    CelumMessageModule,
    CelumRemainingCharactersComponent,

    IconButtonComponent,
    SearchAndSelectComponent,
    TableCountComponent,
    UserAvatarComponent,
    UserFilterComponent,
    UserNamePipe,
    AsyncPipe
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useValue: {
        // FIXME: for some reason a different ErrorStateMatcher is used here than in the rest of the application, so it needs to be imported again
        isErrorState: (control: FormControl | null, _: FormGroupDirective | NgForm | null) => (control.dirty || control.touched) && control.invalid
      }
    },
    CreateEditUserGroupDialogService
  ]
})
export class CreateEditUserGroupDialogComponent {
  protected UserState = UserState;

  protected userGroupForm = this.formBuilder.nonNullable.group({
    groupName: this.formBuilder.nonNullable.control<string>(this.data.existingUserGroup?.name || '', [CelumValidators.required, CelumValidators.maxLength(50)])
  });

  protected removeIcon = new IconConfiguration('cancel-m').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20);

  protected noResultsConfig = EmptyPage.noActionConfig(
    'empty-user-groups-list',
    'no-group',
    'COMPONENTS.USER_GROUPS.CREATE_DIALOG.SELECT_GROUP_MEMBERS',
    'small',
    148
  );

  protected userLimit = SaccProperties.properties.userGroupMemberLimit;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) protected data: CreateEditUserGroupDialogData,
    protected dialogRef: MatDialogRef<CreateEditUserGroupDialogComponent>,
    protected service: CreateEditUserGroupDialogService
  ) {
    this.service.init(data.accountAccess?.accountId || data.accountId, data.existingUserGroup, data.isReadonly);
    this.service.vm$
      .pipe(
        map(vm => vm.hasNameConflict),
        distinctUntilChanged(),
        takeUntilDestroyed()
      )
      .subscribe(hasNameConflict => this.userGroupForm.controls.groupName.setErrors(hasNameConflict ? { nameConflict: true } : null));
  }

  protected trackByFn(_: number, item: AccountMember): string {
    return item.email;
  }

  protected editUserGroup(): void {
    this.service.editUserGroup({
      userGroupName: this.userGroupForm.controls.groupName.value
    });
  }

  protected createUserGroup(): void {
    this.service.createUserGroup(this.userGroupForm.controls.groupName.value);
  }
}

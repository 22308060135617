import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumEmptyPageModule, CelumIconModule, CelumLoadingMaskModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumDirectivesModule, CelumPipesModule, TranslationModule } from '@celum/ng2base';
import { AccountAccess, AccountMember, UserGroup } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe } from '@celum/sacc/shared';
import { SearchAndSelectComponent } from '@celum/shared/ui';
import { UserAvatarComponent } from '@celum/shared/ui-people';

import { GroupFilterService } from './group-filter.service';
import { GroupAvatarComponent } from '../../../group-avatar/group-avatar.component';

@Component({
  selector: 'sacc-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  // This is needed because the style of the search and select must be changed
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    OverlayModule,
    TranslateModule,
    TranslationModule,

    MatChipsModule,
    MatTooltipModule,

    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumPipesModule,

    GroupAvatarComponent,
    GroupToAvatarConfigPipe,
    SearchAndSelectComponent,
    UserAvatarComponent
  ],
  providers: [GroupFilterService]
})
export class GroupFilterComponent {
  @HostBinding('attr.data-test-group-filter') public host = true;
  @Output() public readonly groupAdded = new EventEmitter<UserGroup>();
  @Output() public readonly groupRemoved = new EventEmitter<UserGroup>();

  protected addNewGroup: IconConfiguration = new IconConfiguration('add-group')
    .withIconSize(32)
    .withColor(ColorConstants.BLUE_GRAY_600)
    .withHoverColor(ColorConstants.BLUE_GRAY_700);

  private internalDisabled = false;

  constructor(public service: GroupFilterService) {}

  @Input()
  public set disabled(disabled: boolean) {
    this.internalDisabled = disabled;
    this.addNewGroup.hoverColor = this.disabled ? ColorConstants.BLUE_GRAY_600 : ColorConstants.BLUE_GRAY_700;
  }

  public get disabled(): boolean {
    return this.internalDisabled;
  }

  @Input()
  public set addedGroups(addedGroups: UserGroup[]) {
    this.service.patchState({ addedGroups });
  }

  @Input()
  public set removedGroups(removedGroups: UserGroup[]) {
    this.service.patchState({ removedGroups });
  }

  @Input()
  public set accountAccess(accountAccess: AccountAccess) {
    this.service.patchState({ accountAccess });
  }

  @Input()
  public set groupLimit(groupLimit: number) {
    this.service.patchState({ groupLimit });
  }

  @Input()
  public set user(user: AccountMember) {
    this.service.patchState({ user });
  }

  protected selectionChanged(current: UserGroup[], previous: UserGroup[]): void {
    if (current.length > previous.length) {
      const newItem = current.find(item => !previous.some(previousItem => previousItem.id === item.id));
      this.groupAdded.emit(newItem);
      return;
    }

    const removedItem = previous.find(item => !current.some(previousItem => previousItem.id === item.id));
    this.groupRemoved.emit(removedItem);
  }
}

@if (service.vm$ | async; as vm) {
  <div class="sacc-invitation-table">
    <div class="sacc-invitation-table_header">
      <sacc-table-count [count]="vm.invitationCount" [label]="'COMPONENTS.ACCOUNT_MEMBERSHIP_PAGE.COUNTER_REQUESTS'"></sacc-table-count>
      <sacc-search-bar
        [placeholder]="'COMPONENTS.USER_INVITATION_TABLE.SEARCH_INVITATION' | translate"
        [searchString]="vm.searchString"
        (changeEvent)="searchChanged($event)"
        (closeEvent)="close()"></sacc-search-bar>
    </div>

    <table
      aria-label="Invitation table"
      class="sacc-invitation-table_table"
      infiniteScroll
      matSort
      mat-table
      [dataSource]="vm.invitations"
      [fromRoot]="true"
      [infiniteScrollContainer]="scrollContainer"
      [trackBy]="invitationTracker"
      (matSortChange)="sortData($event)"
      (scrolled)="onScroll()">
      <ng-container matColumnDef="profile-picture">
        <th *matHeaderCellDef id="profile-picture-header" mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <celum-user-avatar [accountAccessToken]="vm.token" [user]="element"></celum-user-avatar>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th *matHeaderCellDef id="name-header" mat-header-cell>{{ 'COMPONENTS.USER_INVITATION_TABLE.NAME' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element | userName: '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th *matHeaderCellDef id="email-header" mat-header-cell>{{ 'COMPONENTS.USER_INVITATION_TABLE.EMAIL' | translate }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef id="status-header" mat-header-cell>{{ 'COMPONENTS.USER_INVITATION_TABLE.STATUS' | translate }}</th>
        <td *matCellDef="let element" mat-cell>
          <sacc-invitation-status [invitationStatus]="element.invitationStatus"></sacc-invitation-status>
        </td>
      </ng-container>

      <ng-container matColumnDef="groups">
        <th *matHeaderCellDef id="groups-header" mat-header-cell>{{ 'COMPONENTS.USER_INVITATION_TABLE.GROUPS' | translate }}</th>
        <td *matCellDef="let element" mat-cell [ngClass]="element.status">
          <sacc-group-avatar-list [groups]="element.groups"></sacc-group-avatar-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="invited-by">
        <th *matHeaderCellDef id="invited-by-header" mat-header-cell>{{ 'COMPONENTS.USER_INVITATION_TABLE.INVITED_BY' | translate }}</th>
        <td *matCellDef="let element" mat-cell>
          @if (element.inviterFirstName && element.inviterLastName) {
            {{ element.inviterFirstName + ' ' + element.inviterLastName }}
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef id="actions-header" mat-header-cell></th>
        <td mat-cell *matCellDef="let element">
          <sacc-icon-button
            [iconConfiguration]="icons.menu"
            [matMenuTriggerFor]="menu"
            [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"
            (click)="$event.stopPropagation()"></sacc-icon-button>
          <mat-menu #menu="matMenu">
            @if (element.canBeResent) {
              <div
                [matTooltip]="
                  !isResendInvitationPossible(element) ? ('COMPONENTS.USER_INVITATION_TABLE.OPTIONS.RESEND_INVITATION_DISABLED' | translate) : null
                ">
                <button mat-menu-item [disabled]="!isResendInvitationPossible(element)" (click)="resendInvitation(element)">
                  <celum-icon [configuration]="icons.resend"></celum-icon>
                  <span>{{ 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.RESEND' | translate }}</span>
                </button>
              </div>
            }
            @if (element.canBeAccepted) {
              <button class="sacc-invitation-table_button-accept" mat-menu-item (click)="approveAccountAccess(element)">
                <celum-icon [configuration]="icons.checkInvite"></celum-icon>
                <span>{{ 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.ACCEPT' | translate }}</span>
              </button>
            }
            @if (element.canBeRejected) {
              <button class="sacc-invitation-table_button-reject" mat-menu-item (click)="disapproveAccountAccess(element)">
                <celum-icon [configuration]="icons.cancelInvite"></celum-icon>
                <span>{{ 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.REJECT' | translate }}</span>
              </button>
            }
            @if (element.canBeDeleted) {
              <button mat-menu-item (click)="deleteInvitation(element)">
                <celum-icon [configuration]="icons.delete"></celum-icon>
                <span>{{ 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.DELETE' | translate }}</span>
              </button>
            }
          </mat-menu>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="vm.displayedColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: vm.displayedColumns"
        mat-row
        [class.sacc-invitation-table_row]="isInvitationEditDialogActive && allowEditingInvitation && vm.canEdit && row.canBeModified"
        (click)="isInvitationEditDialogActive && allowEditingInvitation && vm.canEdit && row.canBeModified && rowClicked(row); $event.stopPropagation()"></tr>
    </table>

    @if (vm.invitations?.length < 1 && vm.userInvitationsLoading === false) {
      <div class="sacc-invitation-table_no-account-users-found">
        <img alt="" src="../../../assets/images/no-users-found.svg" />
        <p>{{ 'COMPONENTS.USER_INVITATION_TABLE.NO_ACCOUNT_USERS_FOUND' | translate }}</p>
      </div>
    }

    @if (vm.userInvitationsLoading) {
      <div class="sacc-invitation-table_loading">
        <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
      </div>
    }
  </div>
}

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { filter } from 'rxjs/operators';

import { AccountAccessStatus } from '@celum/authentication';
import { AccountAccess, AccountMember, UserGroup } from '@celum/sacc/domain';

export type GroupSelectorServiceState = {
  user?: AccountMember;
  hasBottom?: boolean;
  isReadonly?: boolean;
  addedGroups: UserGroup[];
  removedGroups: UserGroup[];
  accountAccess?: AccountAccess;
};

export type GroupSelectorServiceViewModel = {
  totalElementCount: number;
  groupsToShow: UserGroup[];
  isGroupFilteringDisabled: boolean;
} & GroupSelectorServiceState;

@Injectable()
export class GroupSelectorService extends ComponentStore<GroupSelectorServiceState> {
  public vm$ = this.select(this.state$.pipe(filter(state => !!state.user)), state => this.createViewModel(state));

  constructor() {
    super({
      addedGroups: [],
      removedGroups: []
    });
  }

  public init(accountAccess: AccountAccess, user: AccountMember): void {
    this.patchState({ accountAccess, user });
  }

  public addGroup(group: UserGroup): void {
    const { removedGroups, addedGroups } = this.get();

    if (removedGroups.some(removedGroup => removedGroup.id === group.id)) {
      this.patchState(() => ({ removedGroups: removedGroups.filter(userGroup => userGroup.id !== group.id) }));
    } else if (!addedGroups.some(addedUser => addedUser.id === group.id)) {
      this.patchState(() => ({ addedGroups: [...addedGroups, group] }));
    }
  }

  public removeGroup(group: UserGroup): void {
    const { removedGroups, addedGroups } = this.get();

    if (addedGroups.some(addedGroup => addedGroup.id === group.id)) {
      this.patchState(() => ({ addedGroups: addedGroups.filter(userGroup => userGroup.id !== group.id) }));
    } else if (!removedGroups.some(removedGroup => removedGroup.id === group.id)) {
      this.patchState(() => ({ removedGroups: [...removedGroups, group] }));
    }
  }

  private createViewModel(state: GroupSelectorServiceState): GroupSelectorServiceViewModel {
    return {
      ...state,
      totalElementCount: state.user.groups.length + state.addedGroups.length - state.removedGroups.length,
      groupsToShow: GroupSelectorService.filterGroupsToShow(state.user.groups, state.addedGroups, state.removedGroups),
      isGroupFilteringDisabled: state.user.status === AccountAccessStatus.INACTIVE
    };
  }

  private static filterGroupsToShow(allGroups: UserGroup[], addedGroups: UserGroup[], removedGroups: UserGroup[]): UserGroup[] {
    return [...addedGroups, ...allGroups].filter(group => !removedGroups.some(groupToRemove => groupToRemove.id === group.id));
  }
}

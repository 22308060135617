import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { AccountMember, BatchDTO } from '@celum/sacc/domain';

export const accountUserActions = createActionGroup({
  source: '[AccountUser]',
  events: {
    'Fetch Batch': emptyProps(),
    'Fetch Batch Success': props<{ batch: BatchDTO<AccountMember> }>(),
    'Fetch Batch Failure': props<{ error: any }>(),
    Search: props<{ resetSearch?: boolean }>(),
    'Search Success': props<{ batch: BatchDTO<AccountMember> }>(),
    'Search Failure': props<{ error: any }>(),
    'Filter Changed': props<{ filter: string }>(),
    'Reset Account Table': emptyProps(),
    'Reset Account Table Filter': emptyProps(),
    'Update Status': props<{ accountUser: AccountMember; keepScopes: boolean }>(),
    'Update Success': props<{ accountUser: AccountMember; wasActivated?: boolean }>(),
    'Update Failure': props<{ error: any }>(),
    Remove: props<{ accountUser: AccountMember; keepScopes: boolean }>(),
    'Remove Success': props<{ accountUserId: string }>(),
    'Remove Failure': props<{ error: any }>(),
    'Sort Changed': props<{ sort: Sort }>()
  }
});

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, ExperiencePrivilege, InvitationStatus, WorkroomPrivilege } from '@celum/authentication';
import { AccountMember, BatchDTO, BatchParams, getPaginationParams } from '@celum/sacc/domain';

import { RestService } from '../services/rest.service';
import { Utils } from '../utils';

export interface AccountMemberChanges {
  role?: AccountUserRole;
  status?: AccountAccessStatus;
  invitationStatus?: InvitationStatus;
  canKeepDriveScopes?: boolean;
  privileges?: {
    work?: WorkroomPrivilege;
    experience?: ExperiencePrivilege;
  };
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
}

export interface AccountMemberUpdateRequest {
  status: AccountAccessStatus;
  canKeepDriveScopes: boolean;
  role: AccountUserRole;
  workPrivilege: WorkroomPrivilege;
  experiencePrivilege: ExperiencePrivilege;
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AccountMemberResourceService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public removeAccountMember(accountId: string, accountMemberId: string, keepScopes: boolean): Observable<string> {
    const url = this.getApiUrl(`/accounts/${accountId}/members/${accountMemberId}`);

    const params = new HttpParams();
    if (keepScopes) {
      params.append('can-keep-drive-scopes', keepScopes.toString());
    }
    return this.httpClient.delete(url, { params }).pipe(map(() => accountMemberId));
  }

  public fetchBatch(accountId: string, batchParams: BatchParams = {}): Observable<BatchDTO<AccountMember>> {
    return this.httpClient.get<BatchDTO<AccountMember>>(this.getApiUrl(`/accounts/${accountId}/users`), {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public search(
    accountId: string,
    filter: string,
    invitationStatusIn: InvitationStatus[],
    statusIn: AccountAccessStatus[],
    batchParams: BatchParams = {}
  ): Observable<BatchDTO<AccountMember>> {
    return this.httpClient.post<BatchDTO<AccountMember>>(
      this.getApiUrl(`/accounts/${accountId}/members/.search`),
      { invitationStatusIn, ...(statusIn && { statusIn: statusIn }), nameOrEmailContains: filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: batchParams.count || this.batchSize
        })
      }
    );
  }

  public updateAccountMember(
    accountId: string,
    userId: string,
    changedValues: AccountMemberChanges,
    accountMember?: AccountMember
  ): Observable<AccountMember | undefined> {
    const body: AccountMemberUpdateRequest = this.prepareUpdateRequest(changedValues);

    return this.httpClient.patch<void>(this.getApiUrl(`/accounts/${accountId}/members/${userId}`), body).pipe(map(() => accountMember));
  }

  private prepareUpdateRequest(changedValues: AccountMemberChanges): AccountMemberUpdateRequest {
    const body: AccountMemberUpdateRequest = {} as AccountMemberUpdateRequest;

    if (changedValues.role) {
      body.role = changedValues.role;
    }

    if (changedValues.privileges?.experience || changedValues.privileges?.work) {
      if (changedValues?.privileges?.work) {
        body.workPrivilege = changedValues.privileges.work;
      }
      if (changedValues?.privileges?.experience) {
        body.experiencePrivilege = changedValues.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : null;
      }
    }

    if (changedValues.status) {
      body.status = changedValues.status;
      body.canKeepDriveScopes = changedValues.canKeepDriveScopes || false;
    }

    if (changedValues?.groupIdsToAdd || changedValues?.groupIdsToRemove) {
      if (changedValues.groupIdsToAdd) {
        body.groupIdsToAdd = changedValues.groupIdsToAdd;
      }
      if (changedValues.groupIdsToRemove) {
        body.groupIdsToRemove = changedValues.groupIdsToRemove;
      }
    }

    return body;
  }
}

import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from, of } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { InvitationStatus, UserService } from '@celum/authentication';

import { invitationActions } from './invitation.action';
import { selectInvitationUserInvitationFilter, selectInvitationUserInvitationNextBatchParams } from './invitation.selectors';
import { Constants } from '../../constants';
import { ErrorFactory } from '../../error.factory';
import { SaccHttpHeaders, SaccHttpHeaderValues } from '../../sacc.http.headers';
import { InvitationResourceService } from '../../services/invitation-resource.service';
import { RepositoryResourceService } from '../../services/repository-resource.service';
import { UserResourceService } from '../../services/user-resource.service';
import { repositoryActions } from '../../store/repository/repository.action';
import { accountActions } from '../account/account.action';
import { selectAccountActiveAccountId } from '../account/account.selectors';
import { accountUserActions } from '../account-user/account-user.action';
import { AppState } from '../app.state';
import { loaderActions } from '../loader/loader.action';
import { notificationActions } from '../notification/notification.action';
import { userActions } from '../user/user.action';
import {
  selectUserAllAccountAccesses,
  selectUserHasCurrentUserAccountAccess,
  selectUserHasCurrentUserRepositoryAssociatedAccountAccess
} from '../user/user.selectors';

@Injectable()
export class InvitationEffects {
  public static navigationDelay = 2000;
  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.invite, invitationActions.rejectInvitation, invitationActions.acceptInvitation),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invitationActions.inviteFailure,
        invitationActions.inviteSuccess,
        invitationActions.acceptInvitationFailure,
        invitationActions.acceptInvitationSuccess,
        invitationActions.rejectInvitationSuccess,
        invitationActions.rejectInvitationFailure
      ),
      map(() => loaderActions.hide())
    )
  );

  public onSortChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.sortChanged),
      map(() => invitationActions.fetchBatch())
    )
  );

  public fetchBatchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.fetchBatchFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.FETCH_USER_DETAILS_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public acceptInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.acceptInvitation),
      mergeMap(({ accountId, invitationId }) =>
        this.store$.select(selectUserAllAccountAccesses).pipe(
          map(accountAccesses => accountAccesses.find(access => access.accountId === accountId)),
          switchMap(accountAccess =>
            accountAccess
              ? of(invitationActions.acceptInvitationSuccess({ accountName: accountAccess.accountName }))
              : this.acceptInvitationHelper$(accountId, invitationId)
          ),
          take(1),
          finalize(() => localStorage.removeItem(Constants.INVITATION_ID_QUERY_PARAM))
        )
      )
    )
  );

  public acceptInvitationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.acceptInvitationSuccess),
      map(response =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.YOU_ARE_NOW_A_MEMBER_OF', {
            account: `${response.accountName}`
          })
        })
      )
    )
  );

  public redirectOnAcceptInvitationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.acceptInvitationSuccess),
      map(() => sessionStorage.getItem(Constants.REDIRECT_URL)),
      filter(redirectURL => !!redirectURL),
      debounceTime(InvitationEffects.navigationDelay),
      map(redirectUrl => repositoryActions.requestSafeRedirect({ url: redirectUrl }))
    )
  );

  public acceptInvitationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.acceptInvitationFailure),
      map(response =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.FAILED_TO_ACCEPT_INVITATION', {
            error: this.translateService.instant(`${response.error}`)
          })
        })
      )
    )
  );

  public requestAccountAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.requestAccountAccess),
      mergeMap(action =>
        this.store$.select(selectUserHasCurrentUserAccountAccess, { accountId: action.accountId }).pipe(
          take(1),
          map(accountAccessFound => {
            if (accountAccessFound) {
              return action.repositoryId
                ? invitationActions.checkRepositoryAccountAssociation({
                    accountId: action.accountId,
                    repositoryId: action.repositoryId
                  })
                : invitationActions.finishCelumServiceAccountAccessRequestAsApproved({ accountId: action.accountId });
            }

            return invitationActions.executeAccountAccessRequest({
              accountId: action.accountId,
              repositoryId: action.repositoryId,
              email: action.email
            });
          }),
          finalize(() => {
            localStorage.removeItem(Constants.REQUEST_ACCOUNT_ACCESS_QUERY_PARAM);
            sessionStorage.removeItem(Constants.CONNECT_VIA_REPO_QUERY_PARAM);
          })
        )
      )
    )
  );

  // user has already access to the specified account, so let check if it is associated with provided repository id
  public checkRepositoryAccountAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.checkRepositoryAccountAssociation),
      mergeMap(action =>
        this.store$
          .select(selectUserHasCurrentUserRepositoryAssociatedAccountAccess, {
            accountId: action.accountId,
            repositoryId: action.repositoryId
          })
          .pipe(
            take(1),
            map(isAccountAssociatedWithRepository => {
              if (isAccountAssociatedWithRepository) {
                return invitationActions.finishCelumServiceAccountAccessRequestAsApproved({
                  accountId: action.accountId
                });
              }

              const headers = new HttpHeaders().set(SaccHttpHeaders.ERROR_KEY, SaccHttpHeaderValues.NO_SUCH_ASSOCIATED_ACCOUNT);
              const fakeErrorResponse = new HttpErrorResponse({ headers });
              return invitationActions.noSuchAssociatedAccountFailure({ error: fakeErrorResponse });
            })
          )
      )
    )
  );

  public executeAccountAccessRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.executeAccountAccessRequest),
      mergeMap(action =>
        this.invitationResourceService.requestAccountAccess(action.repositoryId, action.accountId, action.email).pipe(
          map(() => invitationActions.executeAccountAccessRequestSuccess({ accountId: action.accountId })),
          catchError(error => {
            const redirectUrl = sessionStorage.getItem(Constants.REDIRECT_URL);
            const errorKey = ErrorFactory.getErrorKey(error);

            if (errorKey === SaccHttpHeaderValues.ACCOUNT_ACCESS_ALREADY_MEMBER && redirectUrl) {
              sessionStorage.removeItem(Constants.REDIRECT_URL);
              return of(invitationActions.finishCelumServiceAccountAccessRequestAsApproved({ accountId: action.accountId }));
            }

            // if there is redirect url specified, let's assume it is from celum service
            if (errorKey === SaccHttpHeaderValues.ACCOUNT_ACCESS_ALREADY_EXISTING_PENDING_APPROVAL && redirectUrl) {
              return of(
                invitationActions.finishCelumServiceAccountAccessRequestAsNotYetApproved({
                  firstRequest: false,
                  accountId: action.accountId
                })
              );
            }

            if (errorKey === SaccHttpHeaderValues.NO_SUCH_ASSOCIATED_ACCOUNT) {
              return of(invitationActions.noSuchAssociatedAccountFailure({ error }));
            }

            return of(invitationActions.executeAccountAccessRequestFailure({ error }));
          })
        )
      )
    )
  );

  // If manager invites user to the account and such user requests account access to the same account afterwards,
  // when such account request is received under these circumstances, then user becomes member of the account
  // but there is no info about user became member sent from BE to FE so here user is still considered as requester to
  // the account and is redirected to the not yet approved page. It should not be a big deal after all because when
  // user is redirected to CH, CH receives his token and user can start working as member of account..
  // except him seeing not yet approved page which is not true in this case
  public executeAccountAccessRequestSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.executeAccountAccessRequestSuccess),
      take(1),
      mergeMap(action =>
        this.userResourceService.getUserDetails(true).pipe(
          tap(user => this.store$.dispatch(userActions.getDetailsSuccess({ user }))),
          map(() =>
            invitationActions.finishCelumServiceAccountAccessRequestAsNotYetApproved({
              firstRequest: true,
              accountId: action.accountId
            })
          ),
          take(1),
          catchError(error => of(error))
        )
      )
    )
  );

  public executeAccountAccessRequestFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.executeAccountAccessRequestFailure),
      map(action => notificationActions.error({ message: ErrorFactory.getErrorMessage(action.error, this.translateService) }))
    )
  );

  public requestAccountAccessViaRepoId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.requestAccountAccessViaRepository),
      withLatestFrom(this.userService.currentUser$),
      mergeMap(([action, currentUser]) =>
        this.repositoryResourceService.isSingleAccountAssociatedWithRepository(action.repositoryId).pipe(
          map(resp => invitationActions.requestAccountAccess({ accountId: resp.accountId, repositoryId: action.repositoryId, email: currentUser.email })),
          catchError(error => {
            const errorKey = ErrorFactory.getErrorKey(error);

            if (errorKey === SaccHttpHeaderValues.MULTI_REPOSITORY_ASSOCIATION) {
              return of(invitationActions.requestRepositoryAssociatedAccount({ repositoryId: action.repositoryId }));
            }

            sessionStorage.removeItem(Constants.CONNECT_VIA_REPO_QUERY_PARAM);
            return of(invitationActions.requestAccountAccessViaRepositoryFailure({ error }));
          })
        )
      )
    )
  );

  public requestAccountAccessViaRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.requestAccountAccessViaRepositoryFailure),
      map(action => notificationActions.error({ message: ErrorFactory.getErrorMessage(action.error, this.translateService) }))
    )
  );

  public requestRepositoryAssociatedAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invitationActions.requestRepositoryAssociatedAccount),
        tap(action => this.router.navigate([`./repository/${action.repositoryId}/accounts`]))
      ),
    { dispatch: false }
  );

  public reloadAccountUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.approveAccountAccessSuccess),
      map(() => accountUserActions.fetchBatch())
    )
  );

  public onInvitationCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.approveAccountAccessSuccess),
      map(() => invitationActions.resetAccountTable())
    )
  );

  public onSelectedAccountChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.selectedAccountChanged),
      switchMap(action => {
        const actions = [];
        if (action.resetFilters) {
          actions.push(invitationActions.resetAccountTableFilter());
        }
        if (action.resetTables) {
          actions.push(invitationActions.resetAccountTable());
        }
        return from(actions);
      })
    )
  );

  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.resetAccountTable, invitationActions.filterChanged),
      withLatestFrom(this.store$.select(selectInvitationUserInvitationFilter)),
      map(([_, searchString]) => invitationActions.search({ filter: searchString, resetSearch: false }))
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.search),
      withLatestFrom(this.store$.select(selectInvitationUserInvitationNextBatchParams), this.store$.select(selectAccountActiveAccountId)),
      switchMap(([action, batchParams, accountId]) => {
        const finalParams = { ...batchParams };
        if (action.resetSearch) {
          finalParams.continuationToken = '';
        }

        return this.invitationResourceService
          .searchNew(
            accountId,
            [InvitationStatus.INVITED, InvitationStatus.DISAPPROVED, InvitationStatus.REJECTED, InvitationStatus.PENDING_APPROVAL],
            action.filter,
            finalParams
          )
          .pipe(
            map(batch => invitationActions.searchSuccess({ batch })),
            catchError(error => of(invitationActions.searchFailure({ error })))
          );
      })
    )
  );

  public invite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.invite),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      mergeMap(([action, accountId]) =>
        this.invitationResourceService.inviteUsers(accountId, action.emails).pipe(
          map(bulkResult => invitationActions.inviteSuccess({ invitations: bulkResult.successful, notInvitedUsers: bulkResult.failed })),
          catchError(error => of(invitationActions.inviteFailure({ error })))
        )
      )
    )
  );

  public inviteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.inviteSuccess),
      mergeMap(action => {
        const notifications = [];

        if (action.notInvitedUsers.length < 1) {
          notifications.push(
            notificationActions.info({
              message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.USERS_INVITED_SUCCESSFUL')
            })
          );
        } else {
          const actions = action.notInvitedUsers.map(user =>
            notificationActions.error({
              message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.COULD_NOT_INVITE', {
                users: user.email,
                error: ErrorFactory.getErrorMessage(user.error, this.translateService)
              })
            })
          );
          notifications.push(...actions);
        }

        return [...notifications, invitationActions.resetAccountTable()];
      })
    )
  );

  public inviteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.inviteFailure),
      map(() =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.USER_INVITATION_FAILURE')
        })
      )
    )
  );

  public rejectInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.rejectInvitation),
      mergeMap(({ accountId, invitationId }) =>
        this.invitationResourceService.rejectInvitation(accountId, invitationId).pipe(
          mergeMap(() =>
            this.userResourceService.getUserDetails().pipe(
              tap(user => this.store$.dispatch(userActions.getDetailsSuccess({ user }))),
              catchError(error => of(error))
            )
          ),
          map(() => invitationActions.rejectInvitationSuccess()),
          catchError(err =>
            of(
              invitationActions.rejectInvitationFailure({
                error: ErrorFactory.getErrorMessage(err, this.translateService)
              })
            )
          ),
          finalize(() => localStorage.removeItem(Constants.INVITATION_ID_QUERY_PARAM))
        )
      )
    )
  );

  public rejectInvitationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.rejectInvitationFailure),
      map(response =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.REJECT_INVITATION.FAILURE', {
            error: this.translateService.instant(`${response.error}`)
          })
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private invitationResourceService: InvitationResourceService,
    private repositoryResourceService: RepositoryResourceService,
    private userResourceService: UserResourceService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private router: Router,
    private userService: UserService
  ) {}

  private getAccountNameHelper$ = (accountId: string) =>
    this.userResourceService.getUserDetails(true).pipe(
      tap(user => this.store$.dispatch(userActions.getDetailsSuccess({ user }))),
      map(user => user.accountAccesses.find(access => access.accountId === accountId).accountName),
      catchError(() => of('the account'))
    );

  private acceptInvitationHelper$ = (accountId: string, invitationId: string) =>
    this.invitationResourceService.acceptInvitation(accountId, invitationId).pipe(
      mergeMap(acceptedAccountId => this.getAccountNameHelper$(acceptedAccountId)),
      map(accountName => invitationActions.acceptInvitationSuccess({ accountName })),
      catchError(err =>
        of(
          invitationActions.acceptInvitationFailure({
            error: ErrorFactory.getErrorMessage(err, this.translateService)
          })
        )
      )
    );
}

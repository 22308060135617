import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { filter, map, take } from 'rxjs/operators';

import { InvitationStatus } from '@celum/authentication';
import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { AccountMember, SaccProperties } from '@celum/sacc/domain';
import { AppState, invitationActions, InvitationService, MemberShipTableColumns } from '@celum/sacc/shared';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { UserNamePipe } from '@celum/shared/util';

import { InvitationTableService } from './invitation-table.service';
import { DeleteDeactivateAccountUserDialogComponent } from '../delete-deactivate-account-user-dialog/delete-deactivate-account-user-dialog.component';
import { EditInvitationDialogComponent } from '../edit-dialogs/edit-invitation-dialog/edit-invitation-dialog.component';
import { GroupAvatarListComponent } from '../group-avatar-list/group-avatar-list.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { InvitationStatusComponent } from '../invitation-status/invitation-status.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';
import { TableCountComponent } from '../table-count/table-count.component';

@Component({
  selector: 'sacc-invitation-table',
  templateUrl: './invitation-table.component.html',
  styleUrl: './invitation-table.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    InfiniteScrollModule,
    TranslateModule,

    MatMenuModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,

    CelumIconModule,

    GroupAvatarListComponent,
    IconButtonComponent,
    InvitationStatusComponent,
    SearchBarComponent,
    TableCountComponent,
    UserAvatarComponent,
    UserNamePipe
  ],
  providers: [InvitationTableService]
})
export class InvitationTableComponent implements OnDestroy {
  @Input()
  public allowEditingInvitation = false;

  public scrollContainer = '.inner-drawer-content';

  protected icons: { [key: string]: IconConfiguration } = {
    menu: new IconConfiguration('option-m').withIconSize(28),
    delete: IconConfiguration.large('remove-l'),
    resend: IconConfiguration.large('mail-l'),
    checkInvite: IconConfiguration.large('check-m').withColor(ColorConstants.SUCCESS),
    cancelInvite: IconConfiguration.large('cancel-l').withColor(ColorConstants.ERROR)
  };

  protected isInvitationEditDialogActive = SaccProperties.properties.featureFlags.invitationEditDialog;

  private readonly allColumns = [
    MemberShipTableColumns.ProfilePicture,
    MemberShipTableColumns.Name,
    MemberShipTableColumns.Email,
    MemberShipTableColumns.Status,
    MemberShipTableColumns.Groups,
    MemberShipTableColumns.InvitedBy,
    MemberShipTableColumns.Actions
  ];

  constructor(
    private store$: Store<AppState>,
    protected service: InvitationTableService,
    private translateService: TranslateService,
    private matDialog: MatDialog,
    private invitationService: InvitationService
  ) {
    this.service.init([...this.allColumns]);
  }

  public ngOnDestroy(): void {
    this.store$.dispatch(invitationActions.resetAccountTableFilter());
  }

  protected invitationTracker(_: number, invitation: AccountMember): string {
    return invitation.id;
  }

  protected sortData(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      return;
    }
    this.store$.dispatch(invitationActions.sortChanged({ sort }));
  }

  protected onScroll(): void {
    if (this.service.allUserInvitationsLoaded || this.service.userInvitationsLoading) {
      return;
    }
    this.service.vm$
      .pipe(
        take(1),
        map(vm => vm.searchString)
      )
      .subscribe(searchString => {
        this.store$.dispatch(invitationActions.search({ filter: searchString, resetSearch: false }));
      });
  }

  protected resendInvitation(userInvitation: AccountMember): void {
    // TODO @CST: Replace with new BE call
    this.invitationService.resendInvitation(userInvitation.id);
  }

  protected deleteInvitation(userInvitation: AccountMember): void {
    const translationKeyBase = 'COMPONENTS.USER_INVITATION_TABLE.DIALOG';
    const dialogConfig = InvitationTableComponent.buildDialogConfig();
    dialogConfig.data = {
      confirmationButtonText: this.translateService.instant(`${translationKeyBase}.CONFIRM`).toUpperCase(),
      cancelButtonText: this.translateService.instant(`${translationKeyBase}.CANCEL`).toUpperCase(),
      type: 'warning'
    };

    switch (userInvitation.invitationStatus) {
      case InvitationStatus.INVITED:
        dialogConfig.data.title = this.translateService.instant(`${translationKeyBase}.REMOVE_INVITATION.TITLE`);
        dialogConfig.data.description = this.translateService.instant(`${translationKeyBase}.REMOVE_INVITATION.DESCRIPTION`, {
          email: userInvitation.email
        });
        break;
      case InvitationStatus.PENDING_APPROVAL:
        dialogConfig.data.title = this.translateService.instant(`${translationKeyBase}.REMOVE_PENDING_INVITATION.TITLE`);
        dialogConfig.data.description = this.translateService.instant(`${translationKeyBase}.REMOVE_PENDING_INVITATION.DESCRIPTION`, {
          email: userInvitation.email
        });
        break;
      case InvitationStatus.DISAPPROVED:
        dialogConfig.data.title = this.translateService.instant(`${translationKeyBase}.REMOVE_DISAPPROVED_INVITATION.TITLE`);
        dialogConfig.data.description = this.translateService.instant(`${translationKeyBase}.REMOVE_DISAPPROVED_INVITATION.DESCRIPTION`, {
          email: userInvitation.email
        });
        break;
      case InvitationStatus.REJECTED:
        dialogConfig.data.title = this.translateService.instant(`${translationKeyBase}.REMOVE_REJECTED_INVITATION.TITLE`);
        dialogConfig.data.description = this.translateService.instant(`${translationKeyBase}.REMOVE_REJECTED_INVITATION.DESCRIPTION`, {
          email: userInvitation.email
        });
        break;
    }

    this.matDialog
      .open(DeleteDeactivateAccountUserDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(
        take(1),
        filter(shouldRemove => shouldRemove)
      )
      .subscribe(() => {
        this.invitationService.deleteInvitation({ invitationId: userInvitation.id, invitationStatus: userInvitation.invitationStatus });
      });
  }

  protected approveAccountAccess(invitation: AccountMember): void {
    this.invitationService.approveAccountAccess(invitation);
  }

  protected disapproveAccountAccess(invitation: AccountMember): void {
    this.invitationService.disapproveAccountAccess(invitation);
  }

  protected isResendInvitationPossible(invitation: AccountMember): boolean {
    return !invitation?.resendAvailableAt || Date.now() > new Date(invitation?.resendAvailableAt).getTime();
  }

  protected searchChanged(event: string): void {
    this.store$.dispatch(invitationActions.filterChanged({ filter: event }));
  }

  protected close(): void {
    this.store$.dispatch(invitationActions.filterChanged({ filter: '' }));
  }

  protected rowClicked(editedInvitation: AccountMember): void {
    this.service.vm$
      .pipe(
        take(1),
        map(vm => ({ accountAccess: vm.accountAccess, currentUser: vm.currentUser }))
      )
      .subscribe(({ accountAccess, currentUser }) => {
        const dialogRef = this.matDialog.open(EditInvitationDialogComponent, {
          width: '84.8rem',
          height: '75.4rem',
          maxWidth: '84.8rem',
          restoreFocus: false,
          autoFocus: false,
          data: {
            editedInvitation,
            accountAccess,
            currentUser
          }
        });
        dialogRef
          .afterClosed()
          .pipe(isTruthy())
          .subscribe(() => this.store$.dispatch(invitationActions.search({ filter: '', resetSearch: true })));
      });
  }

  private static buildDialogConfig(): MatDialogConfig<any> {
    return {
      autoFocus: false
    } as MatDialogConfig;
  }
}

@if (service.vm$ | async; as vm) {
  <celum-dialog class="sacc-edit-invitation-dialog" data-test-edit-invitation-dialog (onCloseAll)="dialogRef.close()">
    <celum-dialog-header>
      <span>{{ 'COMPONENTS.EDIT_INVITATION_DIALOG.HEADER' | translate }}</span>
    </celum-dialog-header>

    <form class="sacc-edit-invitation-dialog_content" [formGroup]="invitationForm">
      <div class="sacc-edit-invitation-dialog_section">
        <h3>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.HEADER' | translate }}</h3>
        <div class="sacc-edit-invitation-dialog_details">
          <celum-user-avatar
            class="sacc-edit-invitation-dialog_details-avatar"
            data-test-edit-invitation-dialog-user-avatar
            [accountAccessToken]="vm.token"
            [size]="78"
            [user]="vm.editedInvitation"></celum-user-avatar>
          <div class="sacc-edit-invitation-dialog_details-subcontainer">
            <span class="sacc-edit-invitation-dialog_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.EMAIL' | translate }}</span>
            <div class="sacc-edit-invitation-dialog_details-text" data-test-edit-invitation-dialog-details-email>{{ vm.editedInvitation.email }}</div>
            <span class="sacc-edit-invitation-dialog_header">{{ 'COMPONENTS.EDIT_INVITATION_DIALOG.DETAILS.INFO.INVITED_BY' | translate }}</span>
            <div class="sacc-edit-invitation-dialog_details-text" data-test-edit-invitation-dialog-details-invited-by>
              {{ vm.editedInvitation.inviterEmail }}
            </div>
          </div>
        </div>
        <sacc-account-user-status-switcher [config]="vm.statusSwitcherConfig" (clicked)="onStatusSwitcherClicked()"></sacc-account-user-status-switcher>
        @if (vm.hasExperienceLicense || vm.hasWorkroomLicense) {
          <div class="sacc-edit-invitation-dialog_privileges-header">
            <span class="sacc-edit-invitation-dialog_privileges-header-text">
              {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.HEADER' | translate }}
            </span>
            <sacc-privilege-info-popup-dialog
              class="sacc-edit-invitation-dialog_privileges-info-icon"
              data-test-sacc-edit-invitation-dialog-privileges-info-icon></sacc-privilege-info-popup-dialog>
          </div>

          @if (vm.hasExperienceLicense) {
            <div class="sacc-edit-invitation-dialog_privileges sacc-edit-invitation-dialog_privileges-experience">
              <sacc-privilege-icon
                class="sacc-edit-invitation-dialog_privileges-icon"
                [iconType]="
                  invitationForm.get('experiencePrivilege').value === ExperiencePrivilege.NONE
                    ? PrivilegeIconType.EXPERIENCE_NOT_APPLIED
                    : PrivilegeIconType.EXPERIENCE
                "
                [showIndicator]="invitationForm.get('experiencePrivilege').value === ExperiencePrivilege.FULL_ACCESS"
                [tooltip]="
                  invitationForm.get('experiencePrivilege').value === ExperiencePrivilege.FULL_ACCESS
                    ? 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS'
                    : 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS_DISABLED'
                "></sacc-privilege-icon>
              <span class="sacc-edit-invitation-dialog_privileges-label">
                {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.LABEL' | translate }}
              </span>
              <mat-form-field
                class="sacc-edit-invitation-dialog_select sacc-edit-invitation-dialog_select-experience sacc-form-field sacc-form-field--no-error">
                <mat-select
                  data-test-edit-invitation-dialog-details-experience-privilege-select
                  [className]="'sacc-edit-invitation-dialog_select-item'"
                  [formControl]="invitationForm.controls.experiencePrivilege"
                  [panelClass]="'select-panel'"
                  [value]="vm.editedInvitation.privileges.experience">
                  <mat-option data-test-edit-invitation-dialog-details-experience-privilege-select-option-full-access [value]="ExperiencePrivilege.FULL_ACCESS">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.EXPERIENCE_PRIVILEGE.FULL_ACCESS' | translate }}
                  </mat-option>
                  <mat-option data-test-edit-invitation-dialog-details-experience-privilege-select-option-none [value]="ExperiencePrivilege.NONE">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.EXPERIENCE_PRIVILEGE.NONE' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          }

          @if (vm.hasWorkroomLicense) {
            <div class="sacc-edit-invitation-dialog_privileges">
              <sacc-privilege-icon
                class="sacc-edit-invitation-dialog_privileges-icon"
                [iconType]="PrivilegeIconType.WORK"
                [showIndicator]="invitationForm.get('workroomPrivilege').value === WorkroomPrivilege.CURATOR"
                [tooltip]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON'"></sacc-privilege-icon>
              <span class="sacc-edit-invitation-dialog_privileges-label">
                {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.LABEL' | translate }}
              </span>
              <mat-form-field class="sacc-edit-invitation-dialog_select sacc-edit-invitation-dialog_select-work sacc-form-field sacc-form-field--no-error">
                <mat-select
                  data-test-edit-invitation-dialog-details-work-privilege-select
                  [className]="'sacc-edit-invitation-dialog_select-item'"
                  [formControl]="invitationForm.controls.workroomPrivilege"
                  [panelClass]="'select-panel'"
                  [value]="vm.editedInvitation.privileges.work">
                  <mat-option data-test-edit-invitation-dialog-details-work-privilege-select-option-curator [value]="WorkroomPrivilege.CURATOR">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.WORK_PRIVILEGE.CURATOR' | translate }}
                  </mat-option>
                  <mat-option data-test-edit-invitation-dialog-details-work-privilege-select-option-participant [value]="WorkroomPrivilege.PARTICIPANT">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.WORK_PRIVILEGE.PARTICIPANT' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          }
        }
      </div>
      <div class="sacc-edit-invitation-dialog_section">
        <sacc-group-selector
          [accountAccess]="vm.accountAccess"
          [user]="vm.editedInvitation"
          (addedGroupsChanged)="service.patchState({ addedGroups: $event })"
          (removedGroupsChanged)="service.patchState({ removedGroups: $event })"></sacc-group-selector>
      </div>
    </form>

    <celum-dialog-footer>
      <sacc-icon-text-button
        class="sacc-edit-invitation-dialog_footer-button-cancel"
        data-test-edit-invitation-dialog-button-cancel
        [label]="'COMMON.CANCEL'"
        [icon]="'cancel-m'"
        (clicked)="dialogRef.close(false)"></sacc-icon-text-button>
      <sacc-icon-text-button
        data-test-edit-invitation-dialog-button-confirm
        [disabled]="!(invitationForm.valid && (invitationForm.dirty || vm.addedGroups.length > 0 || vm.removedGroups.length > 0))"
        [icon]="'check-m'"
        [label]="'COMMON.SAVE'"
        (clicked)="updateInvitation()"></sacc-icon-text-button>
    </celum-dialog-footer>
  </celum-dialog>
}

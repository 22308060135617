import { Sort } from '@angular/material/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { AccountMember } from '@celum/sacc/domain';

export interface AccountUserState extends EntityState<AccountMember> {
  loading: boolean;
  sort: Sort;
  continuationToken: string;
  totalCount: number;
  filterCount: number;
  filter: string;
}

export const accountUserAdapter: EntityAdapter<AccountMember> = createEntityAdapter<AccountMember>();

export const initialAccountUserState: AccountUserState = accountUserAdapter.getInitialState({
  sort: null,
  continuationToken: '',
  loading: false,
  totalCount: 0,
  filterCount: 0,
  filter: ''
});

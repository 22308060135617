import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { InvitationStatus } from '@celum/authentication';
import { tapAllResponses } from '@celum/core';
import { AccountMember } from '@celum/sacc/domain';
import { ErrorService } from '@celum/shared/util';

import { InvitationResourceService } from './invitation-resource.service';
import { LoaderService } from './loader.service';
import { NotificationService } from './notification.service';
import { ErrorFactory } from '../error.factory';
import { selectAccountActiveAccountId } from '../store/account/account.selectors';
import { accountUserActions } from '../store/account-user/account-user.action';
import { AppState } from '../store/app.state';
import { invitationActions } from '../store/invitation/invitation.action';

@Injectable({
  providedIn: 'root'
})
export class InvitationService extends ComponentStore<any> {
  public resendInvitation = this.effect<string>(invitationId$ =>
    invitationId$.pipe(
      tap(() => this.loaderService.show()),
      concatLatestFrom(() => this.store$.select(selectAccountActiveAccountId)),
      switchMap(([invitationId, accountId]) => this.invitationResourceService.resendInvitation(accountId, invitationId)),
      tapAllResponses(
        invitation => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.resendInvitationSuccess({ invitation }));
          this.notificationService.info('SERVICES.ACCOUNT_USER.EFFECTS.INVITATION.RESEND_SUCCESS');
          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_USER.EFFECTS.INVITATION.RESEND_FAILURE')
      )
    )
  );

  public approveAccountAccess = this.effect<AccountMember>(invitation$ =>
    invitation$.pipe(
      tap(() => this.loaderService.show()),
      switchMap(invitation => this.invitationResourceService.approveAccountAccess(invitation)),
      tapAllResponses(
        ({ accountUser, id }) => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(accountUserActions.fetchBatch());
          this.store$.dispatch(invitationActions.approveAccountAccessSuccess({ accountUser, id }));
          this.notificationService.info('SERVICES.ACCOUNT_USER.EFFECTS.ACCESS_REQUEST.APPROVED');
          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_USER.EFFECTS.ACCESS_REQUEST.APPROVED_FAILURE')
      )
    )
  );

  public disapproveAccountAccess = this.effect<AccountMember>(invitation$ =>
    invitation$.pipe(
      tap(() => this.loaderService.show()),
      switchMap(invitation => this.invitationResourceService.disapproveAccountAccess(invitation)),
      tapAllResponses(
        invitation => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.disapproveAccountAccessSuccess({ invitation }));
          this.notificationService.info('SERVICES.ACCOUNT_USER.EFFECTS.ACCESS_REQUEST.REJECTED');
          this.loaderService.hide();
        },
        error => this.handleOperationFailure(error, 'SERVICES.ACCOUNT_USER.EFFECTS.ACCESS_REQUEST.REJECTED_FAILURE')
      )
    )
  );

  public deleteInvitation = this.effect<{ invitationId: string; invitationStatus: InvitationStatus }>(invitationId$ =>
    invitationId$.pipe(
      tap(() => this.loaderService.show()),
      concatLatestFrom(() => this.store$.select(selectAccountActiveAccountId)),
      switchMap(([{ invitationId, invitationStatus }, accountId]) =>
        combineLatest([this.invitationResourceService.removeInvitation(accountId, invitationId, invitationStatus), of(invitationStatus)])
      ),
      tapAllResponses(
        ([invitationId, invitationStatus]) => {
          // TODO: Remove/Replace this once the componentstore here will store the actual invitations
          this.store$.dispatch(invitationActions.deleteInvitationSuccess({ id: invitationId, invitationStatus }));
          this.notificationService.info(this.getDeleteInvitationSuccessMessageKey(invitationStatus));
          this.loaderService.hide();
        },
        error => {
          const messageKey = this.getDeleteInvitationFailureMessageKey((error as any).invitationStatus);
          this.handleOperationFailure(error, messageKey);
        }
      )
    )
  );

  constructor(
    private invitationResourceService: InvitationResourceService,
    private store$: Store<AppState>,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) {
    super({});
  }

  private getDeleteInvitationFailureMessageKey(invitationStatus: InvitationStatus): string {
    const prefix = 'SERVICES.ACCOUNT_USER.EFFECTS';

    if (invitationStatus === InvitationStatus.DISAPPROVED) {
      return `${prefix}.DISAPPROVED_REQUEST.DELETION_FAILURE`;
    }
    if (invitationStatus === InvitationStatus.REJECTED) {
      return `${prefix}.REJECT_INVITATION.DELETION_FAILURE`;
    }
    if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      return `${prefix}.PENDING_APPROVAL.DELETION_FAILURE`;
    }

    return `${prefix}.INVITATION.DELETION_FAILURE`;
  }

  private getDeleteInvitationSuccessMessageKey(invitationStatus: InvitationStatus): string {
    const prefix = 'SERVICES.ACCOUNT_USER.EFFECTS';

    if (invitationStatus === InvitationStatus.DISAPPROVED) {
      return `${prefix}.DISAPPROVED_REQUEST.DELETION_SUCCESS`;
    }
    if (invitationStatus === InvitationStatus.REJECTED) {
      return `${prefix}.REJECT_INVITATION.DELETION_SUCCESS`;
    }
    if (invitationStatus === InvitationStatus.PENDING_APPROVAL) {
      return `${prefix}.PENDING_APPROVAL.DELETION_SUCCESS`;
    }

    return `${prefix}.INVITATION.DELETION_SUCCESS`;
  }

  private handleOperationFailure(error: any, messageKey: string): void {
    const specialErrorMessage = ErrorFactory.getErrorMessage(error, this.translateService);
    const specialError = specialErrorMessage ? { error: specialErrorMessage } : undefined;

    this.errorService.error('InvitationService', this.translateService.instant(messageKey, specialError), error);
    this.loaderService.hide();
  }
}

import { Component, EventEmitter, Input, Output, signal, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccountMember } from '@celum/sacc/domain';

import { ActivationStatusComponent } from '../../activation-status/activation-status.component';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { InvitationStatusComponent } from '../../invitation-status/invitation-status.component';

export interface AccountUserStatusSwitcherConfig {
  userOrInvitation: AccountMember;
  disabled: boolean;
  buttonIcon: string;
  buttonLabel: string;
  buttonTooltip: string;
  switchedTextHeader: string;
  switchedText: string;
  valueNotYetChanged: boolean;
  isInvitation: boolean;
}

@Component({
  selector: 'sacc-account-user-status-switcher',
  templateUrl: './account-user-status-switcher.component.html',
  styleUrl: './account-user-status-switcher.component.scss',
  standalone: true,
  imports: [TranslateModule, MatTooltipModule, ActivationStatusComponent, IconTextButtonComponent, InvitationStatusComponent],
  // Needed to style the icon-text-button
  encapsulation: ViewEncapsulation.None
})
export class AccountUserStatusSwitcherComponent {
  @Input()
  public config: AccountUserStatusSwitcherConfig;

  @Output()
  public readonly clicked = new EventEmitter<any>();

  protected statusSwitched = signal(false);

  protected onStatusSwitchClicked(): void {
    this.statusSwitched.set(true);
    this.clicked?.emit();
  }
}

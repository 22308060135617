import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatDialogModule, MatTooltipModule, TranslateModule],
  selector: 'clickable-popup-dialog',
  templateUrl: './clickable-popup-dialog.component.html',
  styleUrls: ['./clickable-popup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClickablePopupDialogComponent {
  @Input() public triggerClass: string;
  @Input() public contentClass: string;
  @Input() public triggerTooltipTranslationKey: string;

  protected isMenuOpen = false;

  @HostListener('click', ['$event'])
  protected onClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  protected menuOpened(): void {
    this.isMenuOpen = true;
  }

  protected menuClosed(): void {
    this.isMenuOpen = false;
  }
}
